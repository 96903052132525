import $ from 'jquery';

let items = $('.content-bottom .child')
let indexServiceSelected = 0
let maxIndex = items.length - 1
let serviceImages = $('.service-image')
let contents = $('.content-top .right .service-content')
let onChangeService = false
let intervalSwitchService
let popupOverlay = $('#popupOverlay');
let popup = $('#popup');
let popupContents = $('.popup-content');
let logo = $('.logo-beelieve')
let menuItems = $('.mobile .menu-service .item')

$('#buttonInformation').click(function () {
    showPopup()
})

$('#closePopup').click(function () {
    hidePopup()
})

$('#hidePopupOverlay').click(function () {
    hidePopup()
})

function showPopup() {
    clearInterval(intervalSwitchService)
    $(popupContents[indexServiceSelected]).addClass('selected')
    popupOverlay.addClass('show')
    popup.addClass('show')
}

function hidePopup() {
    popup.removeClass('show')
    setTimeout(function () {
        popupOverlay.removeClass('show')
        popupContents.removeClass('selected')
        setIntervalSwitchService()
    }, 500)
}

function itemClick(target, isManual = false) {
    let index = $(target).index()
    if (onChangeService || index === indexServiceSelected) {
        return
    }
    if (isManual) {
        clearInterval(intervalSwitchService)
    }

    onChangeService = true
    indexServiceSelected = index
    items.removeClass('selected')
    $(target).addClass('selected')

    contents.removeClass('selected')
    $(contents[index]).addClass('selected')

    let selectedImageService = $('.service-image.selected')
    selectedImageService.addClass('blur').removeClass('selected')
    setTimeout(function () {
        $(serviceImages[index]).addClass('selected').removeClass('blur')
        onChangeService = false
        if (isManual) {
            setIntervalSwitchService()
        }
    }, 500)
}

items.click(function () {
    itemClick(this, true)
})

setIntervalSwitchService()

function setIntervalSwitchService() {
    intervalSwitchService = setInterval(function () {
        let index = indexServiceSelected + 1
        if (index > maxIndex) {
            index = 0
        }
        itemClick($(items[index]), false)
    }, 10000)
}

menuItems.click(function () {
    let value = $(this).attr("data-select");
    window.location.href = "service.html?service=" + value
})

$('#contactUs').click(function () {
    window.open("https://www.facebook.com/profile.php?id=61569895786792", '_blank').focus();
})